import React from 'react'
import HeroSimple from './HeroSimple'
import HeroWithCTA from './HeroWithCTA'
import { Hero as Props } from '../../../graphql/contentfulTypes'

const Hero = ({ heroType = 'simple', image, text, citySlug, ...restData }: Props & { citySlug: string }) => {
  if (heroType === 'with-cta') return <HeroWithCTA citySlug={citySlug} data={{ image, text, ...restData }} />

  return <HeroSimple image={image} text={text} />
}

export default Hero
