/* eslint-disable react/no-danger */
import React from 'react'
import styled from 'styled-components'
import { Image, toCssPrefix, breakpoints } from '@ecommerce/shared'
import { ContentfulImage } from '../../../graphql/contentfulTypes'

type Props = {
  text: string
  image: ContentfulImage
}

const { cssPrefix, toPrefix } = toCssPrefix('MomentHero__')

const Wrapper = styled.div`
  width: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  position: relative;
  z-index: 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: ${({ theme }) => theme.colors.black50};
  }

  .${cssPrefix} {
    &image {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &text {
      font-size: 42px;
      line-height: 42px;
      color: ${({ theme }) => theme.colors.brandText.default};
      font-weight: 100;
      text-transform: uppercase;
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    height: 388px;

    .${cssPrefix} {
      &text {
        font-size: 55px;
        line-height: 55px;
      }
    }
  }
`

const HeroSimple = ({ text, image }: Props) => {
  return (
    <Wrapper className={cssPrefix}>
      <Image className={toPrefix('image')} src={image.file.url} alt="moment-hero" preload={false} />
      <h2 className={toPrefix('text')} dangerouslySetInnerHTML={{ __html: text }} />
    </Wrapper>
  )
}

export default HeroSimple
