import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { toCssPrefix, Image, Button, breakpoints } from '@ecommerce/shared'
import { Hero } from '../../../graphql/contentfulTypes'

type Props = {
  data: Omit<Hero, 'heroType'>
  citySlug: string
}

type CTAProps = {
  images: {
    mobile: string
    tablet: string
    desktop: string
  }
}

const { cssPrefix, toPrefix } = toCssPrefix('HeroWithCTA__')

const Wrapper = styled.div`
  padding: 30px 10px 0;

  .${cssPrefix} {
    &inner {
      padding: 25px;
      border-radius: 40px;
      background: ${({ theme }) => theme.colors.brand.default};
      display: flex;
      flex-direction: column-reverse;
      max-width: 1400px;
      margin: auto;
    }

    &text {
      color: ${({ theme }) => theme.colors.brandText.default};
      margin: 18px 0;
      text-align: center;
    }

    &button {
      min-width: 240px;
      font-size: 15px;
      margin: auto;
      display: block;
      height: 50px;
      white-space: nowrap;
      padding: 0 20px;
    }

    &right {
      &-image {
        width: 100%;
        max-height: 260px;
        object-fit: contain;
        user-select: none;
        user-drag: none;
      }
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    padding: 35px 25px 0;

    .${cssPrefix} {
      &inner {
        flex-direction: row;
        align-items: center;
        padding: 45px 0 20px 25px;
      }

      &left,
      &right {
        width: 50%;
      }

      &right {
        &-image {
          max-height: 340px;
          margin-left: 5px;
        }
      }

      &text {
        margin: 30px 0;
        text-align: left;
      }

      &button {
        margin: 0;
        font-size: 18px;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    padding: 0px 65px 0;

    .${cssPrefix} {
      &inner {
        padding: 20px 0 20px 70px;
      }

      &text {
        margin: 20px 0;
        font-size: 24px;
        max-width: 400px;
      }

      &button {
        width: 330px;
        height: 72px;
      }

      &right {
        &-image {
          max-height: 420px;
          margin-left: 0;
        }
      }
    }
  }
`

const CallToAction = styled.div<CTAProps>`
  width: 100%;
  height: 60px;
  background-image: url(${({ images }) => images.mobile});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (${breakpoints.tabletLandscape.min}) {
    background-image: url(${({ images }) => images.tablet});
    height: 150px;
    background-position: left;
  }

  @media screen and (${breakpoints.desktop.min}) {
    background-image: url(${({ images }) => images.desktop});
    height: 200px;
  }
`

const HeroWithCTA = ({ data, citySlug }: Props) => {
  const {
    image,
    text,
    buttonText,
    buttonSlug,
    callToActionImageDesktop,
    callToActionImageMobile,
    callToActionImageTablet,
  } = data

  return (
    <Wrapper className={cssPrefix}>
      <div className={toPrefix('inner')}>
        <div className={toPrefix('left')}>
          <CallToAction
            images={{
              desktop: callToActionImageDesktop.file.url,
              tablet: callToActionImageTablet.file.url,
              mobile: callToActionImageMobile.file.url,
            }}
            className={toPrefix('call-to-action')}
          />
          <p className={toPrefix('text')}>{text}</p>
          <Button onClick={() => navigate(`/${citySlug}/${buttonSlug}`)} className={toPrefix('button')} btnColor="blue">
            {buttonText}
          </Button>
        </div>
        <div className={toPrefix('right')}>
          <Image className={toPrefix('right-image')} src={image.file.url} alt="call-to-action" />
        </div>
      </div>
    </Wrapper>
  )
}

export default HeroWithCTA
