import React, { useEffect, useMemo } from 'react'
import loadable from '@loadable/component'
import { City, sendDataToGTM, sendClickProductToGTM, useLocation, Anchor, breakpoints } from '@ecommerce/shared'
import { PageProps, navigate } from 'gatsby'
import styled, { DefaultTheme, ThemeProvider } from 'styled-components'
import { Moment, PgPageProps } from '../../types/PgPages'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import Hero from '../../components/Moments/Hero'
import { contentfulThemeToThemeExtension } from '../../graphql/global/theme'
import { useCartStockErrorHandler } from '../../utils/errors'
import { BlockType } from '../../graphql/contentfulTypes'
import { backgroundImageStyles } from '../../utils/styles'
import { Icon } from '../../components/Icon/Icon'
import useAlgoliaFetch from '../../hooks/useAlgoliaFetch'
import { extractBlocksSkus } from '../utils'

const ProductSlidesList = loadable(() => import('../../components/Moments/ProductSlidesList'))
const PromosGrid = loadable(() => import('../../components/Moments/PromosGrid'))
const FilteredProductList = loadable(() => import('../../components/Moments/FilteredProductList'), { ssr: false })
const ProductsList = loadable(() => import('../home/SkuList'), { ssr: false })
const RecipeList = loadable(() => import('../../components/Recipes/RecipesList'))
const NewsList = loadable(() => import('../../components/News/NewsList'))
const VideosList = loadable(() => import('../../components/Moments/VideosList'))
const BrandsList = loadable(() => import('../../components/Moments/BrandsList'))

type Props = PageProps<{}, { data: Moment; city: City } & PgPageProps['pageContext']>

const TemplateWrapper = styled.div<{ backgroundImage?: string }>`
  background: ${({ theme }) => theme.colors.bodyBackground.default};
  color: ${({ theme }) => theme.colors.bodyText};
  ${backgroundImageStyles()}

  .MomentProductList {
    padding-bottom: 30px;
  }
`

const AnchorWrapper = styled.div`
  max-width: 1500px;
  margin: auto;
  display: none;
  padding: 20px 65px;
  .go-back {
    display: flex;
  }
  @media (${breakpoints.desktop.min}) {
    display: block;
  }
`

const MomentTemplate = ({ pageContext }: Props) => {
  const {
    data: { title, slug, description, keywords = [], blocks, theme: pageTheme, navbar },
    city,
    productCategories,
  } = pageContext
  const themeExtension = pageTheme ? contentfulThemeToThemeExtension(pageTheme) : {}
  const theme = (currentTheme: DefaultTheme) => ({
    ...currentTheme,
    colors: { ...currentTheme.colors, ...themeExtension },
  })

  const { isBolivia } = useLocation()

  const stockErrorHandler = useCartStockErrorHandler()
  const themeColor = pageTheme?.brandDefault

  const skus = useMemo(() => extractBlocksSkus(blocks), [])
  const { products, loadingProducts } = useAlgoliaFetch({
    city,
    skus,
    navigationState: {
      themeId: pageTheme?.contentful_id,
    },
  })

  useEffect(() => {
    sendDataToGTM({ event: 'categoryView', pageTitle: title, categoryName: title })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Layout
        currentCity={city}
        title={title}
        navbarType={navbar?.type}
        navbarTitlte={navbar?.title}
        categories={productCategories}
      >
        <Seo keywords={keywords} description={description}>
          {themeColor && <meta name="theme-color" content={themeColor} />}
        </Seo>
        <TemplateWrapper backgroundImage={pageTheme?.bodyBackgroundImage?.file?.url}>
          <AnchorWrapper>
            <Anchor onClick={() => navigate(`/${city.slug}`)} className="go-back">
              <Icon iconId="arrow_left" size="15" />
              <span>Volver al inicio</span>
            </Anchor>
          </AnchorWrapper>
          {blocks?.map((block) => {
            switch (block.blockType) {
              case BlockType.HERO: {
                const data = block.items[0]
                return <Hero key={block.contentful_id} citySlug={city.slug} {...data} />
              }

              case BlockType.PRODUCT_SLIDE: {
                return (
                  <ProductSlidesList
                    key={block.contentful_id}
                    title={block.title}
                    items={block.items}
                    city={city}
                    themeId={pageTheme?.contentful_id}
                    name={block.name}
                  />
                )
              }

              case BlockType.PROMO_XL_GRID: {
                return (
                  <PromosGrid
                    key={block.contentful_id}
                    items={block.items.map((item) => ({
                      ...item,
                      actionSlug:
                        item.actionSlug || item.actionSlugLong
                          ? `/${city.slug}/${item.actionSlugLong?.actionSlugLong ?? item.actionSlug}`
                          : undefined,
                    }))}
                    title={block.title}
                  />
                )
              }

              case BlockType.FILTERED_LIST: {
                const { contentful_id } = block
                return (
                  <FilteredProductList
                    key={contentful_id}
                    city={city}
                    blockId={contentful_id}
                    themeId={pageTheme?.contentful_id}
                    onCardClick={({ product, listName, themeId }) => {
                      sendClickProductToGTM(product, listName, isBolivia())
                      navigate(`/${city.slug}/products/${product.skuCode}`, {
                        state: { themeId, originListName: listName },
                      })
                    }}
                  />
                )
              }

              case BlockType.RECIPE: {
                return (
                  <RecipeList
                    key={block.contentful_id}
                    title={block.title}
                    items={block.items.map((item) => ({
                      ...item,
                      slug: `/${city.slug}/recetas/${item.slug}`,
                    }))}
                    actionSlug={block.actionSlug ? `/${city.slug}/momento/${block.actionSlug}` : undefined}
                  />
                )
              }

              case BlockType.BRANDS_LIST: {
                const data = block.items[0]
                return (
                  <BrandsList
                    key={block.contentful_id}
                    title={block.title}
                    brands={data.brands}
                    background={data.background}
                  />
                )
              }

              case BlockType.PRODUCT: {
                return (
                  <ProductsList
                    pageProducts={products}
                    loadingProducts={loadingProducts}
                    key={block.contentful_id}
                    styleProps={{ bgTop: 'transparent' }}
                    stockErrorHandler={stockErrorHandler}
                    slugLocation={city.slug}
                    listId={block.contentful_id}
                    className="MomentProductList"
                  />
                )
              }

              case BlockType.VIDEO: {
                return <VideosList key={block.contentful_id} title={block?.title} items={block.items} />
              }

              case BlockType.NEWS: {
                return (
                  <NewsList
                    key={block.contentful_id}
                    title={block?.title}
                    items={block.items.map((item) => ({
                      ...item,
                      slug: `/${city.slug}/momento/${slug}/noticias/${item.slug}`,
                    }))}
                  />
                )
              }

              default:
                return null
            }
          })}
        </TemplateWrapper>
      </Layout>
    </ThemeProvider>
  )
}

export default MomentTemplate
